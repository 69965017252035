<script setup lang="ts">
const trustpilotRef = ref<HTMLElement | null>(null)

onMounted(() => {
  nextTick(() => {
    if (window.Trustpilot && trustpilotRef.value)
      window.Trustpilot.loadFromElement(trustpilotRef.value, true)
  })
})
</script>

<template>
  <!-- TrustBox widget -->
  <div  ref="trustpilotRef" class="trustpilot-widget w-[219px] min-w-[219px] max-w-[219px] sm:w-[220px] sm:min-w-[220px] sm:max-w-[220px] ml-[-62px] sm:-ml-4" data-locale="da-DK" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="5e41cf7c5dd2990001312c45" data-style-height="52px" data-style-width="100%">
  <a href="https://dk.trustpilot.com/review/kassebil.dk" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->
</template>
